var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100 h-auto"},[_c('div',{staticClass:"card shadow-none w-100 p-0 m-0"},[_c('p',{staticClass:"text-primary text-center"}),_c('div',{staticClass:"card-body p-0 m-0"},[_c('validation-observer',{ref:"custom_risk_form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmitClick.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Risk"}},[_c('template',{slot:"label"},[_vm._v(" Risk "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"vid":"risk.risk_id","name":"Risk"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","placeholder":"Search & Select","taggable":"","options":_vm.riskOptions,"state":errors.length > 0 ? false : null},on:{"search":function (searchQuery, loading) {
                    _vm.getRisksAndSetOptions({ search: searchQuery }, loading);
                  }},scopedSlots:_vm._u([{key:"spinner",fn:function(ref){
                  var loading = ref.loading;
return [(loading)?_c('b-spinner',{staticClass:"vs__spinner",attrs:{"small":"","variant":"info"}}):_vm._e()]}}],null,true),model:{value:(_vm.risk),callback:function ($$v) {_vm.risk=$$v},expression:"risk"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],2),_c('b-form-group',{attrs:{"label":"Threat"}},[_c('template',{slot:"label"},[_vm._v(" Threat "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"w-100 d-flex align-items-start justify-content-between"},[_c('div',{staticClass:"w-100"},[_c('validation-provider',{attrs:{"vid":"threat.threat_id","name":"Threats","rules":{
                    required: true,
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{attrs:{"disabled":_vm.risk && _vm.risk.isAlreadyExisting,"label":"name","taggable":"","placeholder":"Search & Select","options":_vm.threatOptions,"state":errors.length > 0 ? false : null},on:{"search":function (searchQuery, loading) {
                        _vm.getThreatsAndSetOptions(
                          { search: searchQuery },
                          loading
                        );
                      }},scopedSlots:_vm._u([{key:"spinner",fn:function(ref){
                      var loading = ref.loading;
return [(loading)?_c('b-spinner',{staticClass:"vs__spinner",attrs:{"small":"","variant":"info"}}):_vm._e()]}}],null,true),model:{value:(_vm.formData.threat),callback:function ($$v) {_vm.$set(_vm.formData, "threat", $$v)},expression:"formData.threat"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(
                  _vm.formData.threat != null &&
                  typeof _vm.formData.threat == 'string'
                )?_c('div',{staticClass:"d-flex align-items-center justify-content-center ml-1",staticStyle:{"width":"60%"}},[_c('validation-provider',{attrs:{"vid":"threat.threat_value","name":"Threat Value","rules":{
                    required: true,
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.vulnerabilityValueOptions},model:{value:(_vm.formData.threat_value),callback:function ($$v) {_vm.$set(_vm.formData, "threat_value", $$v)},expression:"formData.threat_value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()])],2),_c('b-form-group',{attrs:{"label":"Vulnerabilities"}},[_c('template',{slot:"label"},[_vm._v(" Vulnerabilities "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"card mb-0 border w-100",staticStyle:{"min-height":"100px"}},[(_vm.formData.vulnerabilities.length <= 0)?_c('div',{staticClass:"card-body d-flex flex-column align-items-start justify-content-around"},[_c('div',{staticClass:"d-flex flex-column justify-content-center align-items-center w-100",staticStyle:{"height":"100px"}},[_c('feather-icon',{staticClass:"mb-1 text-secondary",attrs:{"icon":"DatabaseIcon","size":"32"}}),_c('p',{staticClass:"mb-0 text-center"},[_vm._v("Vulnerabilities Empty")])],1)]):_c('div',{staticClass:"card-body d-flex flex-column align-items-center justify-content-start px-1 py-75",staticStyle:{"min-height":"100px"}},_vm._l((_vm.formData.vulnerabilities),function(vulnerability,i){return _c('div',{key:i,staticClass:"w-100 d-flex align-items-center justify-content-between mt-75"},[_c('div',{staticClass:"d-flex align-items-center justify-content-center",staticStyle:{"width":"100%"}},[_c('validation-provider',{staticClass:"w-100",attrs:{"vid":"vulnerabilities","name":"Vulnerability","rules":{
                        required: true,
                      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-select',{staticClass:"w-100",attrs:{"disabled":_vm.risk && _vm.risk.isAlreadyExisting,"label":"name","taggable":"","placeholder":"Search & Select","options":_vm.vulnerabilityOptions},on:{"input":function (vul) {
                            _vm.handleVulnerabilityChange(vul, i);
                          },"search":function (searchQuery, loading) {
                            _vm.getVulnerabilitiesAndSetOptions(
                              { search: searchQuery },
                              loading
                            );
                          }},scopedSlots:_vm._u([{key:"spinner",fn:function(ref){
                          var loading = ref.loading;
return [(loading)?_c('b-spinner',{staticClass:"vs__spinner",attrs:{"small":"","variant":"info"}}):_vm._e()]}}],null,true),model:{value:(vulnerability.name),callback:function ($$v) {_vm.$set(vulnerability, "name", $$v)},expression:"vulnerability.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(
                      !vulnerability.value || !vulnerability.value == null
                    )?_c('div',{staticClass:"d-flex align-items-center justify-content-center ml-1",staticStyle:{"width":"60%"}},[_c('validation-provider',{attrs:{"name":"Vulnerability Value","vid":("vulnerabilities." + i + ".vulnerability_value"),"rules":{
                        required: true,
                      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-select',{attrs:{"disabled":_vm.risk && _vm.risk.isAlreadyExisting,"options":_vm.vulnerabilityValueOptions},model:{value:(vulnerability.vulnerability_value),callback:function ($$v) {_vm.$set(vulnerability, "vulnerability_value", $$v)},expression:"vulnerability.vulnerability_value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),_c('div',{staticClass:"d-flex align-items-center justify-content-center ml-1"},[_c('b-button',{staticClass:"btn-icon rounded-circle",attrs:{"disabled":_vm.risk && _vm.risk.isAlreadyExisting,"variant":"danger"},on:{"click":function($event){return _vm.removeSelectedVulnerability(i)}}},[_c('feather-icon',{staticClass:"text-white",attrs:{"icon":"XIcon","size":"18"}})],1)],1)])}),0),_c('div',{staticClass:"card-footer bg-light-secondary d-flex align-items-center justify-content-start py-75"},[_c('b-button',{attrs:{"disabled":_vm.risk && _vm.risk.isAlreadyExisting,"variant":"primary","size":"sm"},on:{"click":_vm.handleNewVulnClick}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Add New")])],1)],1)])],2),_c('div',{staticClass:"w-100 d-flex align-items-center justify-content-end mt-2"},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"outline-warning"},on:{"click":_vm.handleResetClick}},[_c('span',{staticClass:"align-middle"},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"RefreshCcwIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Reset")])],1)]),_c('b-button',{attrs:{"type":"submit","variant":"success","disabled":invalid ||
                _vm.risk == null ||
                _vm.formData.threat == null ||
                _vm.formData.vulnerabilities.length == 0}},[(_vm.isLoading)?[_c('b-spinner',{staticClass:"mr-50",attrs:{"small":"","variant":"light"}})]:[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"CheckIcon"}})],_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.isLoading ? "Please Wait.." : "Submit"))])],2)],1)],1)]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }