<template>
  <div class="w-100 h-auto">
    <div class="card shadow-none w-100 p-0 m-0">
      <p class="text-primary text-center"></p>
      <!-- <div class="card-header pb-75">
        <div class="d-flex align-items-center justify-content-between w-100">
          <div
            class="d-flex flex-column align-items-center justify-content-start w-100"
          >
            <h3 class="mb-25 font-weight-bolder text-center">Custom Risks</h3>
            <p class="mb-0 text-center">Create your custom risks.</p>
          </div>
          <div class="d-flex flex-row align-items-center justify-content-end">
        
          </div>
        </div>
      </div> -->

      <div class="card-body p-0 m-0">
        <validation-observer ref="custom_risk_form" #default="{ invalid }">
          <b-form @submit.prevent="handleSubmitClick">
            <b-form-group label="Risk">
              <template slot="label">
                Risk <span class="text-danger">*</span>
              </template>
              <validation-provider
                vid="risk.risk_id"
                #default="{ errors }"
                name="Risk"
              >
                <v-select
                  v-model="risk"
                  label="name"
                  placeholder="Search & Select"
                  taggable
                  :options="riskOptions"
                  @search="
                    (searchQuery, loading) => {
                      getRisksAndSetOptions({ search: searchQuery }, loading);
                    }
                  "
                  :state="errors.length > 0 ? false : null"
                >
                  <template #spinner="{ loading }">
                    <b-spinner
                      class="vs__spinner"
                      v-if="loading"
                      small
                      variant="info"
                    />
                  </template>
                </v-select>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Threat">
              <template slot="label">
                Threat <span class="text-danger">*</span>
              </template>
              <div
                class="w-100 d-flex align-items-start justify-content-between"
              >
                <div class="w-100">
                  <validation-provider
                    vid="threat.threat_id"
                    #default="{ errors }"
                    name="Threats"
                    :rules="{
                      required: true,
                    }"
                  >
                    <v-select
                      :disabled="risk && risk.isAlreadyExisting"
                      v-model="formData.threat"
                      label="name"
                      taggable
                      placeholder="Search & Select"
                      :options="threatOptions"
                      @search="
                        (searchQuery, loading) => {
                          getThreatsAndSetOptions(
                            { search: searchQuery },
                            loading
                          );
                        }
                      "
                      :state="errors.length > 0 ? false : null"
                    >
                      <template #spinner="{ loading }">
                        <b-spinner
                          class="vs__spinner"
                          v-if="loading"
                          small
                          variant="info"
                        />
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>

                <div
                  class="d-flex align-items-center justify-content-center ml-1"
                  style="width: 60%"
                  v-if="
                    formData.threat != null &&
                    typeof formData.threat == 'string'
                  "
                >
                  <validation-provider
                    vid="threat.threat_value"
                    #default="{ errors }"
                    name="Threat Value"
                    :rules="{
                      required: true,
                    }"
                  >
                    <b-form-select
                      v-model="formData.threat_value"
                      :options="vulnerabilityValueOptions"
                    ></b-form-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </div>
            </b-form-group>
            <!-- 
            <b-form-group label="Vulnerabilities">
              <validation-provider
                vid="vulnerabilities"
                #default="{ errors }"
                name="Risk"
              >
                <v-select
                  :disabled="risk && risk.isAlreadyExisting"
                  v-model="formData.vulnerabilities"
                  label="name"
                  placeholder="Search & Select"
                  multiple
                  :options="vulnerabilityOptions"
                  @search="
                    (searchQuery, loading) => {
                      getVulnerabilitiesAndSetOptions(
                        { search: searchQuery },
                        loading
                      );
                    }
                  "
                  :state="errors.length > 0 ? false : null"
                ></v-select>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group> -->

            <b-form-group label="Vulnerabilities">
              <template slot="label">
                Vulnerabilities <span class="text-danger">*</span>
              </template>

              <div class="card mb-0 border w-100" style="min-height: 100px">
                <div
                  v-if="formData.vulnerabilities.length <= 0"
                  class="card-body d-flex flex-column align-items-start justify-content-around"
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-center w-100"
                    style="height: 100px"
                  >
                    <feather-icon
                      icon="DatabaseIcon"
                      class="mb-1 text-secondary"
                      size="32"
                    />
                    <p class="mb-0 text-center">Vulnerabilities Empty</p>
                  </div>
                </div>

                <div
                  v-else
                  class="card-body d-flex flex-column align-items-center justify-content-start px-1 py-75"
                  style="min-height: 100px"
                >
                  <div
                    :key="i"
                    v-for="(vulnerability, i) in formData.vulnerabilities"
                    class="w-100 d-flex align-items-center justify-content-between mt-75"
                  >
                    <div
                      class="d-flex align-items-center justify-content-center"
                      style="width: 100%"
                    >
                      <validation-provider
                        vid="vulnerabilities"
                        #default="{ errors }"
                        name="Vulnerability"
                        :rules="{
                          required: true,
                        }"
                        class="w-100"
                      >
                        <v-select
                          class="w-100"
                          :disabled="risk && risk.isAlreadyExisting"
                          v-model="vulnerability.name"
                          @input="
                            (vul) => {
                              handleVulnerabilityChange(vul, i);
                            }
                          "
                          label="name"
                          taggable
                          placeholder="Search & Select"
                          :options="vulnerabilityOptions"
                          @search="
                            (searchQuery, loading) => {
                              getVulnerabilitiesAndSetOptions(
                                { search: searchQuery },
                                loading
                              );
                            }
                          "
                        >
                          <template #spinner="{ loading }">
                            <b-spinner
                              class="vs__spinner"
                              v-if="loading"
                              small
                              variant="info"
                            />
                          </template>
                        </v-select>

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </div>
                    <div
                      v-if="
                        !vulnerability.value || !vulnerability.value == null
                      "
                      class="d-flex align-items-center justify-content-center ml-1"
                      style="width: 60%"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Vulnerability Value"
                        :vid="`vulnerabilities.${i}.vulnerability_value`"
                        :rules="{
                          required: true,
                        }"
                      >
                        <b-form-select
                          :disabled="risk && risk.isAlreadyExisting"
                          v-model="vulnerability.vulnerability_value"
                          :options="vulnerabilityValueOptions"
                        ></b-form-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </div>
                    <div
                      class="d-flex align-items-center justify-content-center ml-1"
                    >
                      <b-button
                        :disabled="risk && risk.isAlreadyExisting"
                        variant="danger"
                        class="btn-icon rounded-circle"
                        @click="removeSelectedVulnerability(i)"
                        ><feather-icon
                          class="text-white"
                          icon="XIcon"
                          size="18"
                        />
                      </b-button>
                    </div>
                  </div>
                </div>
                <div
                  class="card-footer bg-light-secondary d-flex align-items-center justify-content-start py-75"
                >
                  <b-button
                    :disabled="risk && risk.isAlreadyExisting"
                    variant="primary"
                    @click="handleNewVulnClick"
                    size="sm"
                    ><feather-icon icon="PlusIcon" class="mr-50" />
                    <span class="align-middle">Add New</span></b-button
                  >
                </div>
                <!-- <small class="text-danger">{{ errors[0] }}</small> -->
              </div>
            </b-form-group>

            <div class="w-100 d-flex align-items-center justify-content-end mt-2">
              <b-button
                @click="handleResetClick"
                variant="outline-warning"
                class="mr-1"
              >
                <span class="align-middle">
                  <feather-icon icon="RefreshCcwIcon" class="mr-50" />
                  <span class="align-middle">Reset</span>
                </span>
              </b-button>
              <b-button
                type="submit"
                variant="success"
                :disabled="
                  invalid ||
                  risk == null ||
                  formData.threat == null ||
                  formData.vulnerabilities.length == 0
                "
              >
                <template v-if="isLoading">
                  <b-spinner small variant="light" class="mr-50" />
                </template>
                <template v-else>
                  <feather-icon icon="CheckIcon" class="mr-50" />
                </template>
                <span class="align-middle">{{
                  isLoading ? "Please Wait.." : "Submit"
                }}</span>
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import {
  BButton,
  BOverlay,
  BBadge,
  BForm,
  BFormGroup,
  BFormTextarea,
  BFormInput,
  BFormSelect,
  BSpinner,
} from "bootstrap-vue";
import RiskMixins from "@/mixins/RiskMixins";
import ResponseMixins from "@/mixins/ResponseMixins";
import UtilsMixins from "@/mixins/UtilsMixins";
import { formatDate } from "@/@core/utils/filter";

export default {
  mixins: [RiskMixins, ResponseMixins, UtilsMixins],
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    required,
    BButton,
    BOverlay,
    BBadge,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormInput,
    BFormSelect,
    BSpinner,
  },
  props: {
    reloadParent: {
      type: Function,
      required: true,
    },
    closeAction: { type: Function, required: true },
  },

  data() {
    return {
      isLoading: false,
      risk: null,
      formData: {
        threat: null,
        threat_value: null,
        vulnerabilities: [],
      },

      riskOptions: [],
      threatOptions: [],
      vulnerabilityOptions: [],
      vulnerabilityValueOptions: [
        { value: null, text: "Select a value" },
        { value: 1, text: "Very Low" },
        { value: 2, text: "Low" },
        { value: 3, text: "Medium" },
        { value: 4, text: "High" },
        { value: 5, text: "Critical" },
      ],
    };
  },

  watch: {
    risk(newValue) {
      if (newValue && newValue != null) {
        if (newValue.threat && newValue.threat != null) {
          const newThreat = {
            name: newValue.threat.name,
            value: newValue.threat._id,
          };
          this.$set(this.formData, "threat", newThreat);
        }

        if (newValue.vulnerabilities && newValue.vulnerabilities != null) {
          const newVulnerabilities = newValue.vulnerabilities.map((vul) => {
            return {
              name: vul.name,
              value: vul._id,
              vulnerability_value: vul.vulnerability_value,
            };
          });
          this.$set(this.formData, "vulnerabilities", newVulnerabilities);
        }
      }
    },
  },

  methods: {
    handleSubmitClick() {
      this.isLoading = true;
      const assetId = this.$route.params.id;
      this.createCustomRiskV2(assetId, {
        ...this.formData,
        risk: this.risk,
      })
        .then((res) => {
          this.handleResponse(res);
          // this.$router.push({ name: "asset-details", params: { id: assetId } });
          this.reloadParent();
          this.closeAction();
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
          if (err.response) {
            if (err.response.status === 422) {
              this.$refs.custom_risk_form.setErrors(err.response.data.errors);
            }
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    handleResetClick() {
      //  risk: null,
      // formData: {
      //   threat: null,
      //   threat_value: null,
      //   vulnerabilities: [],
      // },
      this.risk = null;
      this.formData = {
        threat: null,
        threat_value: null,
        vulnerabilities: [],
      };
    },

    handleVulnerabilityChange(vul, index) {
      console.log("Selected VUL", vul);
      const newVulnerabilities = this.formData.vulnerabilities;
      if (typeof vul == "string") {
        newVulnerabilities[index] = {
          name: vul,
          value: null,
          vulnerability_value: null,
        };
      } else {
        newVulnerabilities[index] = { ...newVulnerabilities[index], ...vul };
      }

      this.$set(this.formData, "vulnerabilities", newVulnerabilities);
      console.log(this.formData.vulnerabilities);
    },

    removeSelectedVulnerability(index) {
      const newVulns = this.formData.vulnerabilities.filter(
        (vul, i) => i !== index
      );
      this.$set(this.formData, "vulnerabilities", newVulns);
    },

    handleNewVulnClick() {
      const newVulns = [
        ...this.formData.vulnerabilities,
        { name: "", value: null, vulnerability_value: null },
      ];
      this.$set(this.formData, "vulnerabilities", newVulns);
    },

    getRisksAndSetOptions(params, loading) {
      loading(true);
      this.debounceFunction(() => {
        this.getRisks(params)
          .then((res) => {
            console.log(res);
            const newRiskOptions = res.data.data.map((r) => {
              return {
                name: r.name,
                value: r._id,
                threat: r.threat,
                vulnerabilities: r.vulnerabilities,
                isAlreadyExisting: true,
              };
            });
            this.riskOptions = newRiskOptions;
          })
          .catch((err) => {
            console.log(err);
            this.handleError(err);
          })
          .finally(() => {
            loading(false);
          });
      });
    },

    getThreatsAndSetOptions(params, loading) {
      loading(true);
      this.debounceFunction(() => {
        this.getThreats(params)
          .then((res) => {
            console.log(res);
            const newThreatOptions = res.data.data.map((r) => {
              return {
                name: r.name,
                value: r._id,
              };
            });
            this.threatOptions = newThreatOptions;
          })
          .catch((err) => {
            console.log(err);
            this.handleError(err);
          })
          .finally(() => {
            loading(false);
          });
      });
    },

    getVulnerabilitiesAndSetOptions(params, loading) {
      loading(true);
      this.debounceFunction(() => {
        this.getVulnerabilities(params)
          .then((res) => {
            console.log(res);
            const newVulnerabilitiesOptions = res.data.data.map((r) => {
              return {
                name: r.name,
                value: r._id,
              };
            });
            this.vulnerabilityOptions = newVulnerabilitiesOptions;
          })
          .catch((err) => {
            console.log(err);
            this.handleError(err);
          })
          .finally(() => {
            loading(false);
          });
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>